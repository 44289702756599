<template>
  <div>
    <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
      <!-- 轮播vue -->

      <!-- 轮播结束 -->

      <div v-swiper:mySwiper="swiperOption">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(banner, index) in banners" :key="'banner' + index">
            <div class="banner">
              <img :src="banner.image">
              <div class="w1200">
                <div class="txt">
                  <p class="fnt_42">{{ banner.title }}</p>
                  <span class="fnt_16">{{ banner.intro }}</span>

                  <div class="learn_more fnt_16" v-if="banner.linkUrl">
                    <router-link class="learn_more fnt_16" :to="banner.linkUrl">
                      {{
                        globalDataArray.siteTemplateLanguage.system_learn_more }} >
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination-bullets" :class="banners.length > 1 ? '' : 'dpn'" id="sPage">
        </div>
      </div>

      <!-- 第二板块 -->
      <div class="problem_mine">
        <div class="w1200">
          <div class="problem_minetop">
            <ul class="problem_ul" v-if="showCategory">
              <template v-if="listQuery.categoryId == ''">
                <li class="problem_li fnt_18 active">
                  <a href="javascript:;" @click="changeCategory(-1)">{{
                    globalDataArray.siteTemplateLanguage.all_list_data }}</a>
                </li>
              </template>
              <template v-else>
                <li class="problem_li fnt_18">
                  <a href="javascript:;" @click="changeCategory(-1)">{{
                    globalDataArray.siteTemplateLanguage.all_list_data }}</a>
                </li>
              </template>
              <template v-for="(item, index) in category">
                <div :key="'category' + index">
                  <li class="problem_li fnt_18 active" :title="item.title" v-if="listQuery.categoryId == item.parentId">
                    <a href="javascript:;" @click="changeCategory(item.parentId)">{{ item.title }}</a>
                  </li>
                  <li class="problem_li fnt_18" v-else :title="item.title">
                    <a
                      href="javascript:;"
                      @click="changeCategory(item.parentId)"
                    >{{ item.title }}</a>
                  </li>
                </div>
              </template>
            </ul>
            <ul class="problem_ul" v-if="showCategory">
              <template v-if="listQuery.technicalId == ''">
                <li class="problem_li fnt_18 active">
                  <a href="javascript:;" @click="changeTechnical(-1)">{{
                    globalDataArray.siteTemplateLanguage.all_list_data }}</a>
                </li>
              </template>
              <template v-else>
                <li class="problem_li fnt_18">
                  <a href="javascript:;" @click="changeTechnical(-1)">{{
                    globalDataArray.siteTemplateLanguage.all_list_data }}</a>
                </li>
              </template>
              <template v-for="(item) in technicalList">
                <div :key="item.id">
                  <li class="problem_li fnt_18 active" :title="item.title" v-if="listQuery.technicalId == item.parentId">
                    <a href="javascript:;" @click="changeTechnical(item.parentId)">{{ item.title }}</a>
                  </li>
                  <li class="problem_li fnt_18" v-else :title="item.title">
                    <a
                      href="javascript:;"
                      @click="changeTechnical(item.parentId)"
                    >{{ item.title }}</a>
                  </li>
                </div>
              </template>
            </ul>
            <div class="problem_lx">
              <span class="mintop_span fnt_16"><img src="@/assets/images/stlb_dy.png" alt=""><span>{{
                globalDataArray.siteTemplateLanguage.site_contact }}：</span><a
                :href="'tel:' + globalDataArray.siteInfo.contact"
              >{{ globalDataArray.siteInfo.contact }}</a></span>
              <span class="mintop_span fnt_16"><img src="@/assets/images/stlb_yx.png" alt=""><span>{{
                globalDataArray.siteTemplateLanguage.site_email }}：</span><a
                :href="'mailto:' + globalDataArray.siteInfo.email"
              >{{
                globalDataArray.siteInfo.email }}</a></span>
            </div>
          </div>
          <!-- 学习赛 -->
          <div class="problem_minebottom" v-if="listQuery.categoryId === '1afbe733655a3a700ac5b1eb33094730'">
            <a href="https://www.devedu.net/" target="_blank">
              <div style="cursor: pointer;">
                <div class="tabulation">
                  <div class="tabulation_left">
                    <img src="../../assets/images/platform.png" alt="提示">
                  </div>
                  <div class="tabulation_right">
                    <div class="right_box">
                      <div class="box_tit1 fnt_24">
                        <a target="_blank" class="item" to="https://www.devedu.net/">
                          开放原子开源大赛在线学习考试平台
                        </a>
                      </div>
                      <div class="box_tit2">
                        <p class="fnt_16"><span>学习工具</span></p>
                        <p class="fnt_16"><span>课程</span></p>
                      </div>
                      <div class="fnt_14">
                        开放原子开源大赛在线学习考试平台是一个便捷、高效的一站式学习工具。这里汇集了丰富多样的开发者课程，满足您全方位的学习需求。同时，平台还提供灵活的在线考试功能，让您随时检验自己的学习成果。无论您身在何处，都能轻松开启学习之旅，实现知识的持续积累与自我价值的不断提升。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <!-- ================================================================ -->
          <div class="problem_minebottom">
            <div>
              <!-- 报名中 -->
              <div class="tabulation" v-for="(item, index) in competition" :key="'competition' + index">
                <div class="tabulation_left" ref="imgDiv">
                  <router-link
                    v-if="fromQuery != '' && fromQuery != null" target="_blank" class="item"
                    :to="'/competitionInfo?id=' + item.parentId + '&from=' + fromQuery"
                  >
                    <img
                      :src="item.image"
                      :style="{ height: imgheight + 'px' }" alt=""
                    >
                  </router-link>
                  <router-link v-else target="_blank" class="item" :to="'/competitionInfo?id=' + item.parentId">
                    <img
                      :src="item.image" :style="{ height: imgheight + 'px' }" alt=""
                    >
                  </router-link>
                </div>
                <div class="tabulation_right" :class="item.signup == '04' ? 'endedPad' : ''">
                  <div class="right_box">
                    <div class="box_tit1 fnt_24" :title="item.title" :class="item.signup == '04' ? 'pr_30' : ''">
                      <router-link
                        v-if="fromQuery != '' && fromQuery != null" target="_blank" class="item"
                        :to="'/competitionInfo?id=' + item.parentId + '&from=' + fromQuery"
                      >
                        {{ item.title }}
                      </router-link>
                      <router-link v-else target="_blank" class="item" :to="'/competitionInfo?id=' + item.parentId">
                        {{ item.title }}
                      </router-link>
                    </div>
                    <div class="box_tit2" :class="item.signup == '04' ? 'pr_30' : ''">
                      <p class="fnt_16" v-for="(val, index1) in item.mark" :key="'val' + index1">
                        <span :title="val">{{ val
                        }}</span>
                      </p>
                    </div>
                    <div class="box_tit3 fnt_14" :title="item.introduction" :class="item.signup == '04' ? 'pr_30' : ''">
                      {{ item.introduction }}
                    </div>
                    <div class="box_tit4">
                      <ul class="box_tit4ul">
                        <li v-if="item.bonus != '' && item.bonus != null">
                          <p class="fnt_22">{{ item.bonus }}</p>
                          <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_bonus }}</span>
                        </li>
                        <li>
                          <p class="fnt_22" v-if="item.teams != null && item.teams != ''">{{ item.teams }}</p>
                          <p class="fnt_22" v-else>0</p>
                          <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_teams }}</span>
                        </li>
                        <li v-if="item.type_dictText != '' && item.type_dictText != null">
                          <p class="fnt_22">{{ item.type_dictText }}</p>
                          <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_type }}</span>
                        </li>
                        <li v-if="item.localEndtime != '' && item.localEndtime != null">
                          <p class="fnt_18">{{ item.localEndtime }}</p>
                          <span class="fnt_14">{{ globalDataArray.siteTemplateLanguage.competition_end_signup }}
                            UTC+8</span>
                        </li>
                      </ul>
                    </div>
                    <div class="box_tit5">
                      <div class="box_tit5_left">
                        {{ globalDataArray.siteTemplateLanguage.competition_co_builder }}:
                        <template v-if="item.organizersLogo">
                          <span
                            class="img" v-for="(val, index1) in item.organizersLogo"
                            :key="'organizersLogo' + index1"
                          ><img :src="val" alt=""></span>
                        </template>
                        <template v-else>
                          <span
                            class="txt" v-if="item.organizers != '' && item.organizers != null"
                            :title="item.organizers[0]"
                          >{{ item.organizers[0] }}</span>
                        </template>
                      </div>

                      <div class="box_tit5_right">
                        <!-- 已结束 -->
                        <template v-if="item.signup == '04'">
                          <div class="ended" v-if="item.signup == '04'">
                            {{ item.signup_dictText }}
                          </div>
                        </template>
                        <template v-else>
                          <!-- 已经报名显示已报名 -->
                          <template v-if="item.isSignup == 1">
                            <div class="started">
                              {{ globalDataArray.siteTemplateLanguage.competition_signup_yes }}
                            </div>
                          </template>

                          <template v-else-if="item.isSignup == 2">
                            <!--申请中-->
                            <div class="started">
                              {{ globalDataArray.siteTemplateLanguage.competition_signup_ing }}
                            </div>
                          </template>

                          <template v-else>
                            <template v-if="currentTime < item.localEndtimeStr">
                              <!-- 未开始跟进行中 -->
                              <div class="started" v-if="item.signup == '01' || item.signup == '02'">
                                {{ item.signup_dictText }}
                              </div>

                              <!-- 报名中 -->
                              <div
                                class="application"
                                @click="toSignFun(item.templateId, item.categoryId, item.parentId, item.teamsUsers, item.teams, item.parentId, item.zoneId)"
                                v-if="item.signup == '03'"
                              >
                                {{ globalDataArray.siteTemplateLanguage.click_signup }}
                              </div>
                            </template>
                            <!-- 截止报名 -->
                            <div class="ove" v-else>
                              {{ globalDataArray.siteTemplateLanguage.competition_signup_end }}
                            </div>
                          </template>
                        </template>
                      </div>
                    </div>

                    <div class="endedIcon" v-if="item.signup == '04'"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p_page" v-if="totalnum > 0">
              <a-pagination
                v-model="curpage" :total="totalnum" :page-size="listQuery.pageSize" @change="turnPageFun"
                :hide-on-single-page="true" show-less-items
              />&emsp;&emsp;<span>{{
                                    globalDataArray.siteTemplateLanguage.total }}
                {{ totalnum }} {{ globalDataArray.siteTemplateLanguage.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 选择报名方式弹窗 -->
    <signway :signway.sync="signway" :cur-info-array.sync="curInfoArray" @itemclick="signtypeFun" />

    <!-- 报名弹窗 -->
    <formsign :formsign.sync="formsign" :cur-info-array.sync="curInfoArray" @itemclick="formsignFun" /> <!--成人-->

    <formteen :formteen.sync="formteen" :cur-info-array.sync="curInfoArray" @itemclick="formsignFun" /> <!--青年-->

    <!-- 加入团队列表 -->
    <formapply :formapply.sync="formapply" :cur-info-array.sync="curInfoArray" @itemclick="formapplyFun" />

    <!-- 加入团队信息填写弹窗 -->
    <formmsg
      :formmsg.sync="formmsg" :cur-info-array.sync="curInfoArray" :team-array.sync="teamArray"
      @itemclick="formmsgFun"
    />

    <!-- 参赛用户服务协议 -->
    <formxieyi :formxieyi.sync="formxieyi" :cur-info-array.sync="curInfoArray" @itemclick="formxieyiFun" />
  </div>
</template>

<script>
import { stringToDateTime } from '@/utils/util'
import Cookies from 'js-cookie'
import { message } from 'ant-design-vue'
import { getCategoryList, getCompetitionList, getCompetitionListForZone, getTechnicalList } from '@/api/saiti'
import { getAdList } from '@/api/api'
import { getZoneDetail } from '@/api/zone'

export default {
  name: 'Saiti',
  data() {
    return {
      title: this.globalDataArray.siteNavigation[1].title + '-' + this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteNavigation[1].keywords,
      keywords: this.globalDataArray.siteNavigation[1].description,
      banners: [],
      category: [],
      technicalList: [],
      competition: [],
      swiperOption: {
        autoplay: 5000,
        initialSlide: 0,
        // loop: true,
        pagination: '.swiper-pagination'
      },
      listQuery: { 'categoryId': '', page: 1, pageSize: 20, technicalId: '' },
      currentTime: new Date().getTime(), // 当前地区时区时间时间戳
      curpage: 1,
      totalnum: 0,

      signway: false,
      signtype: '',

      formsign: false,
      formteen: false,
      formen: false,
      formenteen: false,

      formapply: false,

      formmsg: false,

      curInfoArray: {
        categoryId: '', // 当前赛题分类id
        competitionId: '', // 当前赛题id
        templateId: '', // 报名模板
        currentLanguge: '',
        teamsUsers: '',
        teams: '',
        parentId: '',
        zoneId: ''

      },
      teamArray: {},
      formxieyi: false,

      imgwidhth: 0,

      fromQuery: ''
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query.zone_id) {
      this.$nextTick(() => {
        this.initZone()
      })
    }
    else {
      this.$nextTick(() => {
        this.init()
      })
    }

    if (to.query.from) {
      this.fromQuery = to.query.from
    }

    next()
  },

  mounted() {
    if (this.$route.query.zone_id) {
      this.initZone()
    }
    else {
      this.init()
    }

    if (typeof window !== 'undefined') {
      window.onresize = () => {
        this.$nextTick(() => {
          if (this.$refs.imgDiv && this.$refs.imgDiv.length > 0) {
            this.imgwidhth = this.$refs.imgDiv[0].offsetWidth
          }
        })
      }
    }

    if (typeof document === 'object') {
      document.documentElement.scrollTop = 0
    }

    if (this.$route.query.from) {
      this.fromQuery = this.$route.query.from
    }
  },
  computed: {
    imgheight() {
      return Math.floor(this.imgwidhth * 0.65)
    },

    showCategory() {
      return !this.$route.query.zone_id
    }
  },
  methods: {
    init() {
      this.listQuery.page = 1

      const adParam = 'alias=competition_banner'

      getAdList(adParam).then(res => {
        if (res.success) {
          this.banners = res.result
          // console.log(res)
          this.$nextTick(() => {
            if (typeof document === 'object' && this.banners != '' && this.banners != null) {
              document.getElementById('sPage').childNodes[0].classList.add('swiper-pagination-bullet-active')
            }
          })
        }
      })

      getCategoryList().then(res => {
        if (res.success) {
          this.category = res.result
        }
      })

      getTechnicalList().then(res => {
        if (res.success) {
          this.technicalList = res.result
        }
      })

      if (this.$route.query.parentId != '' && this.$route.query.parentId != undefined) {
        const id = this.$route.query.parentId
        this.changeCategory(id)

        if (typeof document === 'object') {
          document.documentElement.scrollTop = 0
        }
      } else {
        this.getCompetitionList()
      }
    },

    initZone() {
      this.listQuery.page = 1

      getZoneDetail('parentId=' + this.$route.query.zone_id).then(res => {
        if (res.success) {
          this.banners = [{
            image: res.result.banner
          }]
          // console.log(res)
          this.$nextTick(() => {
            if (typeof document === 'object' && this.banners != '' && this.banners != null) {
              document.getElementById('sPage').childNodes[0].classList.add('swiper-pagination-bullet-active')
            }
          })
        }
      })

      this.category = []

      this.getCompetitionListForZone()
    },

    changeCategory(id) {
      const that = this
      that.listQuery.categoryId = id == -1 ? '' : id
      that.listQuery.page = 1
      this.getCompetitionList()
    },

    changeTechnical(id) {
      this.listQuery.technicalId = id == -1 ? '' : id
      this.listQuery.page = 1
      this.getCompetitionList()
    },

    getCompetitionList() {
      const that = this
      const param = 'categoryId=' + that.listQuery.categoryId + '&page=' + that.listQuery.page + '&pageSize=' + that.listQuery.pageSize + '&technicalId=' + that.listQuery.technicalId
      getCompetitionList(param).then(res => {
        if (res.success && res.result != '' && Object.keys(res.result).length > 0) {
          that.totalnum = res.result.total
          that.curpage = res.result.current
          that.competition = res.result.records
          for (var i in that.competition) {
            // that.competition[i].markArr = that.competition[i].mark.split(',');
            if (that.competition[i].mark) {
              const mark = that.competition[i].mark
              that.competition[i].mark = mark.split(',')
            }

            if (that.competition[i].organizersLogo) {
              const organizersLogo = that.competition[i].organizersLogo
              that.competition[i].organizersLogo = organizersLogo.split(',')
            }

            if (that.competition[i].organizers) {
              const organizers = that.competition[i].organizers
              that.competition[i].organizers = organizers.split(',')
            }

            // 将utc时间转为当地时间并作比较
            const endUtcTime = that.competition[i].endUtcTime
            const localEndtimeStr = new Date(endUtcTime).getTime()
            that.competition[i]['localEndtime'] = stringToDateTime(localEndtimeStr)
            that.competition[i]['localEndtimeStr'] = localEndtimeStr
          }

          that.$nextTick(() => {
            if (this.$refs.imgDiv && this.$refs.imgDiv.length > 0) {
              that.imgwidhth = that.$refs.imgDiv[0].offsetWidth
            }
          })

          // res.result.records.forEach((item,index)=>{
          //   //console.log(new Date(item.endUtcTime).getTime())
          // })

          // console.log(that.competition)
        }
      })
    },

    getCompetitionListForZone() {
      const that = this
      const param = 'zoneId=' + this.$route.query.zone_id + '&page=' + that.listQuery.page + '&pageSize=' + that.listQuery.pageSize

      getCompetitionListForZone(param).then(res => {
        if (res.success && res.result != '' && Object.keys(res.result).length > 0) {
          that.totalnum = res.result.total
          that.curpage = res.result.current
          that.competition = res.result.records
          for (var i in that.competition) {
            // that.competition[i].markArr = that.competition[i].mark.split(',');
            if (that.competition[i].mark) {
              const mark = that.competition[i].mark
              that.competition[i].mark = mark.split(',')
            }

            if (that.competition[i].organizersLogo) {
              const organizersLogo = that.competition[i].organizersLogo
              that.competition[i].organizersLogo = organizersLogo.split(',')
            }

            if (that.competition[i].organizers) {
              const organizers = that.competition[i].organizers
              that.competition[i].organizers = organizers.split(',')
            }

            // 将utc时间转为当地时间并作比较
            const endUtcTime = that.competition[i].endUtcTime
            const localEndtimeStr = new Date(endUtcTime).getTime()
            that.competition[i]['localEndtime'] = stringToDateTime(localEndtimeStr)
            that.competition[i]['localEndtimeStr'] = localEndtimeStr
          }

          that.$nextTick(() => {
            if (this.$refs.imgDiv && this.$refs.imgDiv.length > 0) {
              that.imgwidhth = that.$refs.imgDiv[0].offsetWidth
            }
          })

          // res.result.records.forEach((item,index)=>{
          //   //console.log(new Date(item.endUtcTime).getTime())
          // })

          // console.log(that.competition)
        }
      })
    },

    turnPageFun(e) {
      this.listQuery.page = e
      this.getCompetitionList()
      // 回调翻页页码
      // console.log(e)
    },

    toSignFun(templateId, categoryId, competitionId, teamsUsers, teams, parentId, zoneId) {
      const that = this
      that.curInfoArray.templateId = templateId
      that.curInfoArray.categoryId = categoryId // 当前赛题分类id
      that.curInfoArray.competitionId = competitionId // 当前赛题id
      that.curInfoArray.teamsUsers = teamsUsers // 当前赛题限制成员数
      that.curInfoArray.teams = teams
      that.curInfoArray.parentId = parentId
      that.curInfoArray.zoneId = zoneId
      if (Cookies.get('DEFAULT_LANG') != '' && Cookies.get('DEFAULT_LANG') != undefined) {
        that.curInfoArray.currentLanguge = Cookies.get('DEFAULT_LANG')
      } else {
        that.curInfoArray.currentLanguge = 'zh_CN'
      }

      // this.signway=true

      let token = ''
      const saitiAccessInfo = Cookies.get('saiti_access_info')
      if (saitiAccessInfo != '' && saitiAccessInfo != undefined && saitiAccessInfo != null) {
        token = JSON.parse(saitiAccessInfo).token
      }
      // console.log(token)
      if (token != '' && token != undefined) {
        that.formxieyi = true
      } else {
        // message.error(that.globalDataArray.siteTemplateLanguage.please_login)
        that.handleLogin()
      }
    },
    // 选择报名方式
    signtypeFun(res) {
      const that = this
      // 自定义事件，把item传给父组件,发射事件
      if (res == '1') { // 报名
        if (that.curInfoArray.templateId == '01') { // 成人
          that.formsign = true
        } else {
          that.formteen = true // 青年
        }
      }

      if (res == '2') { // 加入团队
        that.formapply = true
      }
    },

    formsignFun(num) {
      const that = this
      // console.log(num)
      if (num == '1') { // 立即报名
        message.success(that.globalDataArray.siteTemplateLanguage.competition_signup_success_tips)
        // setTimeout(function(){
        //   location.reload();
        // },1000)
        // 报名成功改变按钮状态
        that.competition.forEach((item, index) => {
          // console.log(item)
          if (item.parentId == that.curInfoArray.competitionId) {
            item.isSignup = '1'
            if (item.teams != null && item.teams != '') {
              item.teams += 1
            } else {
              item.teams = '1'
            }
          }
        })
      }

      if (num == '2') { // 取消、关闭

      }
    },

    formapplyFun(res) {
      // console.log(res,'res')
      const that = this
      if (res.clickType == '1') { // 加入
        that.teamArray = res
        that.formmsg = true
      }

      if (res.clickType == '2') { // 取消、关闭
        // that.formmsg=false
      }
    },

    formmsgFun(res) {
      const that = this
      if (res == '1') { // 确认
        // this.formapply=true

        // 报名成功改变按钮状态
        that.competition.forEach((item, index) => {
          // console.log(item)
          if (item.parentId == that.teamArray.competitionId) {
            item.isSignup = '2'
          }
        })
      }

      if (res == '2') { // 取消、关闭
        this.formapply = true
      }
    },

    formxieyiFun(res) {
      const that = this
      if (res == '1') { // 同意
        this.signway = true
      }

      if (res == '2') { // 取消、关闭

      }
    },

    handleLogin() {
      const redirectUrl = encodeURIComponent(location.href)
      Cookies.set('atom_redirect', redirectUrl, { domain: '.atomgit.com', expires: 180, path: '/', secure: true })
      location.href = this.logAuthUrl
    }
  },
  metaInfo() {
    let title = ''
    let description = ''
    let keywords = ''
    if (this.globalDataArray.siteNavigation[1].title != '' && this.globalDataArray.siteNavigation[1].title != null && this.globalDataArray.siteNavigation[1].title != ' ') {
      title = this.globalDataArray.siteNavigation[1].title + '-' + this.globalDataArray.siteInfo.title
    } else {
      title = this.globalDataArray.siteInfo.title
    }
    if (this.globalDataArray.siteNavigation[1].description != '' && this.globalDataArray.siteNavigation[1].description != null) {
      description = this.globalDataArray.siteNavigation[1].description
    } else {
      description = this.globalDataArray.siteInfo.description
    }
    if (this.globalDataArray.siteNavigation[1].keywords != '' && this.globalDataArray.siteNavigation[1].keywords != null) {
      keywords = this.globalDataArray.siteNavigation[1].keywords
    } else {
      keywords = this.globalDataArray.siteInfo.keywords
    }
    return {
      title: title,
      meta: [
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'keywords', name: 'keywords', content: keywords }
      ]
    }
  }
}
</script>

<style>
@import '@/assets/css/problem.css';
</style>
