export function convertUTCTimeToLocalTime(UTCDateString) {
  if (!UTCDateString) {
    return '-'
  }
  function formatFunc(str) { // 格式化显示
    return str > 9 ? str : '0' + str
  }
  var date2 = new Date(UTCDateString) // 这步是关键
  var year = date2.getFullYear()
  var mon = formatFunc(date2.getMonth() + 1)
  var day = formatFunc(date2.getDate())
  var hour = date2.getHours()
  var noon = hour >= 12 ? 'PM' : 'AM'
  hour = hour >= 12 ? hour - 12 : hour
  hour = formatFunc(hour)
  var min = formatFunc(date2.getMinutes())
  var dateStr = year + '-' + mon + '-' + day + ' ' + noon + ' ' + hour + ':' + min
  return dateStr
}

// 将时间戳转为年月日时分秒
export function stringToDateTime(value) {
  const time = new Date(value)
  const year = time.getFullYear()
  let month = (time.getMonth() + 1)
  let date = time.getDate()
  let hour = time.getHours()
  let minute = time.getMinutes()
  let second = time.getSeconds()
  month = month < 10 ? '0' + month : month
  date = date < 10 ? '0' + date : date
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  const str = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
  return str
}
